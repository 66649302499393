import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit, inject
} from '@angular/core';
import {
  AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { find } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '~auth/services/auth.service';
import { AuthState } from '~auth/states/auth.state';
import { UI_BUILD_VERSION } from '~core/constants/version';
import { AppState } from '~core/states/app/app.state';
import { WINDOW } from '~core/services/window/window.service';
import { SAMLDataService } from '~shared/services/apiSAMLController';
import { STILFacilityDataService } from '~shared/services/apiSTILFacilityController';
import { SafeTKDB } from '~indexedDB';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: [ './login-page.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class LoginPageComponent implements OnInit {

  private appState = inject(AppState);
  private authSvc = inject(AuthService);
  private destroyRef = inject(DestroyRef);
  private formBuilder = inject(UntypedFormBuilder);
  private facDataSvc = inject(STILFacilityDataService);
  private cdr = inject(ChangeDetectorRef);
  private indexedDB = inject(SafeTKDB);
  private route = inject(ActivatedRoute);
  private toastr = inject(ToastrService);
  private samlDataSvc = inject(SAMLDataService);
  private window = inject(WINDOW);
  private state = inject(AuthState);

  uiVersion = UI_BUILD_VERSION;
  connectionStringNames: any[];
  facilities: any[];
  idpSettings: any;
  useSSO = false;
  stilUseSSO = false;
  apiRedirect: string;
  filteredUsers;
  loginForm: UntypedFormGroup;
  loading = false;
  returnUrl: string;
  showInvalidCredsMsg = false;
  submitted = false;
  flag: string;
  ssoToken: string;
  loggedOut: boolean;
  stilLoggingIn: boolean;
  loggedInViaSSO = false;

  get connectionStringNameCtrl(): AbstractControl {
    return this.loginForm.get('connectionStringName');
  }

  get facilityIdCtrl(): AbstractControl {
    return this.loginForm.get('facilityId');
  }

  get passwordCtrl(): AbstractControl {
    return this.loginForm.get('password');
  }

  get usernameCtrl(): AbstractControl {
    return this.loginForm.get('username');
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      connectionStringName: [ '', Validators.required ],
      facilityId: [ '', Validators.required ],
      username: [ '' ],
      password: [ '' ]
    });

    this.connectionStringNameCtrl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((connectionStringName: string) => {
          if ((!!this.useSSO || !!this.stilUseSSO) && !!this.ssoToken) {
            return this.facDataSvc.getActiveFacilitiesForSSOUser(this.ssoToken, connectionStringName);
          } else {
            return this.facDataSvc.getRecords(true, connectionStringName);
          }

        })
      )
      .subscribe((facilities) => {
        this.facilities = facilities;

        if (facilities.length) {
          const defaultFacility = facilities[0];
          this.loginForm.patchValue({ facilityId: defaultFacility.ID });
          this.cdr.markForCheck();
        } else {
          this.toastr.error('No Facilities were found. Please try another Connection String name.');
        }
      });

    this.passwordCtrl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(pw => this.showInvalidCredsMsg = false);

    this.route.data
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({
        loginPageData: {
          connectionStringNames, defaultConnectionStringName, defaultFacility, facilities, idpSettings, users
        }
      }) => {
        this.connectionStringNames = connectionStringNames;
        this.facilities = facilities;
        this.idpSettings = idpSettings;
        this.apiRedirect = this.idpSettings.spAPIRedirect;
        this.ssoToken = this.route.snapshot.queryParams.Token;
        this.flag = this.route.snapshot.queryParams.flag;
        this.useSSO = this.idpSettings?.useSSO && !this.flag;
        this.stilUseSSO = this.idpSettings?.stilUseSSO;
        this.stilLoggingIn = this.stilUseSSO && (this.flag == "SSOSafeTKPro" || !!this.ssoToken);
        this.loggedInViaSSO = this.appState.get('loggedInViaSSO');

        this.loggedOut = this.state.get('loggedOut');

        this.loginForm.patchValue({
          connectionStringName: defaultConnectionStringName,
          facilityId: +defaultFacility.ID
        }, { emitEvent: false });
      });
  }

  async onSubmit(): Promise<void> {
    const {
      connectionStringName, facilityId, password, username
    } = this.loginForm.value;
    this.submitted = true;
    this.loading = true;

    const selectedFacility = find(this.facilities, { ID: +facilityId });

    if (((this.loginForm.invalid || !this.ssoToken) && (this.useSSO || this.stilLoggingIn)) || ((this.loginForm.invalid || !username) && !(this.useSSO || this.stilLoggingIn))) {
      this.toastr.error('Invalid user for this facility');
      this.loading = false;
      return;
    }

    let loginCall$ = this.authSvc.login(connectionStringName, facilityId, username, password);

    if (!!this.useSSO || !!this.stilLoggingIn) {
      this.appState.set('loggedInViaSSO', true);
      loginCall$ = this.samlDataSvc.ssoLogin(connectionStringName, facilityId, this.ssoToken);
    }

    const authObj = await firstValueFrom(loginCall$);

    if (authObj) {
      localStorage.setItem('lastConnectionStringName', connectionStringName);
      this.appState.set('connectionStringName', connectionStringName);
      this.appState.set('facility', selectedFacility);
      this.showInvalidCredsMsg = false;

      const { user } = authObj;
      this.appState.set('user', user);
      this.authSvc.setSession(authObj);
      await this.authSvc.redirectUser();
    } else {
      this.resetLoginForm();
    }
  }

  private resetLoginForm(): void {
    this.loginForm.patchValue({
      username: '',
      password: ''
    });
    this.submitted = false;
    this.loading = false;
    this.showInvalidCredsMsg = true;
    this.cdr.markForCheck();
  }

  startOver(): void {
    this.appState.set('loggedInViaSSO', false);
    this.window.location.href = this.apiRedirect;
  }
}


