
import {
  ChangeDetectionStrategy, Component, OnInit, inject
} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NotificationsService } from '../../service/notifications.service';

@Component({
  selector: 'app-notifications-icon',
  imports: [
    MatBadgeModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './notifications-icon.html',
  styleUrls: [ './notifications-icon.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsIconComponent implements OnInit {

  private notificationsSvc = inject(NotificationsService);

  readonly unreadCount = this.notificationsSvc.unreadCount;

  ngOnInit(): void {
    this.notificationsSvc.updateReadCount();
  }

  toggleNotifications() {
    this.notificationsSvc.showNotifications.set(!this.notificationsSvc.showNotifications());
  }
}
