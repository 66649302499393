import {
  HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi
} from '@angular/common/http';
import {
  ErrorHandler, NgModule, inject, provideAppInitializer 
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { DialogModule } from '@ngneat/dialog';
import {
  popperVariation, provideTippyConfig, tooltipVariation,
  withContextMenuVariation
} from '@ngneat/helipopper';

import { HighchartsChartModule } from 'highcharts-angular';
import { QuillModule } from 'ngx-quill';
import { ToastrModule } from 'ngx-toastr';
import { DEFAULT_DIALOG_OPTIONS } from '~core/constants/default-dialog-options.constant';
import { ApiUrlInterceptorService } from '~core/interceptors/api-url-interceptor.service';
import { NullPatchInterceptor } from '~core/interceptors/null-patch.interceptor';
import { AppService, InitializeAppState } from '~core/services/app/app.service';
import { TranslatePipe } from '~shared/pipes/translate.pipe';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { DateModule } from './date/date.module';
import { DefaultPageComponent } from './default-page/default-page.component';
import { OfflineModule } from './offline/offline.module';
import { SignalrModule } from './signalr/signalr.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LoadingService, initializeLoading } from '~core/services/loading/loading.service';
import { PermitEditState, initializePermitEditState } from '~permit/permit-edit/state/permit-edit.state';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { provideHighlightOptions } from 'ngx-highlightjs';
import { provideHotToastConfig } from '@ngxpert/hot-toast';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { statusInstancesVariation } from '~core/constants/tippy-status-instances-variation';

@NgModule({
  declarations: [ AppComponent, DefaultPageComponent ],
  bootstrap: [ AppComponent ],
  imports: [
    AppRoutingModule,
    AuthModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    CommonModule,
    DialogModule.forRoot(),
    DateModule,
    FormsModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatDialogModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatIconModule,
    MatInputModule,
    QuillModule.forRoot({
      theme: 'snow',
      modules: {
        imageResize: {
          modules: [ 'Resize', 'DisplaySize' ]
        }
      }
    }),
    PdfViewerModule,
    ReactiveFormsModule,
    RouterModule,
    ToastrModule.forRoot(),
    SignalrModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    OfflineModule,
    HighchartsChartModule
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideHighlightOptions({
      coreLibraryLoader: () => import('highlight.js/lib/core'),
      lineNumbersLoader: () => import('ngx-highlightjs/line-numbers'), // Optional, add line numbers if needed
      languages: {
        json: () => import('highlight.js/lib/languages/json')
      }
    }),
    provideHotToastConfig({
      autoClose: true,
      position: 'top-right'
    }),
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: {
          ...tooltipVariation,
          theme: 'light',
          arrow: true
        },
        popper: popperVariation,
        contextMenu: withContextMenuVariation(popperVariation),
        statusInstances: statusInstancesVariation
      }
    }),
    TranslatePipe,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        closeOnNavigation: true
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiUrlInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NullPatchInterceptor,
      multi: true
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: DEFAULT_DIALOG_OPTIONS
    },
    Title,
    provideAppInitializer(() => {
      const initializerFn = (InitializeAppState)(inject(AppService));
      return initializerFn();
    }),
    provideAppInitializer(() => {
      const initializerFn = (initializeLoading)(inject(LoadingService));
      return initializerFn();
    }),
    provideAppInitializer(() => {
      const initializerFn = (initializePermitEditState)(inject(PermitEditState));
      return initializerFn();
    }),
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService
    }
  ]
})
export class AppModule { }

