import {
  Component, DestroyRef, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation,
  inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '~auth/services/auth.service';
import { LayoutService } from '~core/services/layout/layout.service';
import { AppState } from '~core/states/app/app.state';
import { FacilityState } from '~core/states/facility/facility.state';
import { SidebarState } from '~core/states/sidebar/sidebar.state';
import { Language } from '~translations/models/language.model';
import { TranslationService } from '~translations/services';
import { TranslationsState } from '~translations/state/translations.state';
import { createAvatar } from '@dicebear/core';
import { initials } from '@dicebear/collection';

export const TRANSLATION_BASE_PATH = 'User_Menu.Menu_Actions';


@Component({
  selector: 'app-user-menu-icon',
  templateUrl: './user-menu-icon.component.html',
  styleUrls: [ './user-menu-icon.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class UserMenuIconComponent implements OnInit {

  private destroyRef = inject(DestroyRef);

  @ViewChild('svgIcon') svgIcon: ElementRef;

  @Input() iconOnly = false;

  activeLanguage$ = this.translations.activeLanguage$;
  facilityName$ = this.appState.facilityName$;
  languages$ = this.translations.languages$;
  pageTitle$ = this.appState.pageTitle$;

  isMobile$ = this.layoutService.isMobile$;

  user: any;
  selectedId: any;
  showSettings = false;
  showRightNav: boolean;
  serverConnectionStatus$ = this.appState.serverConnectionStatus$;
  serverConnectionStatusIconEnabled = false;

  svg: any;
  translationBasePath = TRANSLATION_BASE_PATH;

  get infoUrl() {
    return this.facilityState.get('generalSettings')?.infoUrl;
  }

  get isActiveDirectory() {
    return this.appState.get('user')?.isActiveDirectoryUser;
  }

  constructor(
    private appState: AppState,
    private authSvc: AuthService,
    private facilityState: FacilityState,
    private translations: TranslationsState,
    private translationService: TranslationService,
    private layoutService: LayoutService,
    private sidebarState: SidebarState
  ) { }


  ngOnInit(): void {
    this.appState.user$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(user =>{
      this.user = user;
      const avatar = createAvatar(initials, {
        seed: `${this.user?.firstName} ${this.user?.lastName}`
      });
      this.svg = avatar.toString();
    });
  }

  logout() {
    this.authSvc.logout().toPromise();
  }

  goToLink() {
    window.open(this.infoUrl, '_blank');
  }

  resetPassword() {
    this.authSvc.openResetPasswordDialog();
  }

  async selectLanguage(language: Language): Promise<void> {
    this.translationService.updateActiveLanguage(language);
  }

  toggleAdminToolbar(): void {
    const currentState = this.sidebarState.get('rightNavCollapsed');
    this.sidebarState.set('rightNavCollapsed', !currentState);
  }

}
