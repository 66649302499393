import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnInit,
  inject,
  input
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Dictionary } from 'lodash';
import { TranslationsState } from '~translations/state/translations.state';


@Component({
  selector: 'app-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: [ './nav-link.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class NavLinkComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  link = input<Dictionary<any>>();

  constructor(
    private cdr: ChangeDetectorRef,
    private translationState: TranslationsState
  ) { }

  ngOnInit() {
    this.translationState.activeLanguage$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.cdr.markForCheck());
  }

  isRecentPermitLink(): boolean {
    return /\[[0-9]+\]/ig.test(this.link()?.name);
  }

  isLastSectionLink(linkName: string): boolean {
    return [ 'Permit Index V2', 'Standard Index V2' ]
      .map(link => link.toLowerCase())
      .includes(linkName.toLowerCase());
  }
}
