import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  ViewEncapsulation,
  WritableSignal,
  inject,
  signal
} from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from '~core/services/layout/layout.service';
import { FacilityState } from '~core/states/facility/facility.state';
import { SidebarState } from '~core/states/sidebar/sidebar.state';

import { AppState } from '../../states/app/app.state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NetworkState } from '~offline/states/network-state/network.state';
import {
  combineLatest, map, switchMap
} from 'rxjs';
import { SafeTKDB } from '~core/services/indexeddb/indexeddb.service';
import _map from 'lodash/map';
import uniq from 'lodash/uniq';

export type DashboardRoute = '/' | '/dashboards' | '/announcements';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [ './sidebar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class SidebarComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  private appState: AppState = inject(AppState);
  private state: SidebarState = inject(SidebarState);
  private layoutService: LayoutService = inject(LayoutService);
  private indexedDB = inject(SafeTKDB);
  private networkState: NetworkState = inject(NetworkState);
  private facilityState: FacilityState = inject(FacilityState);
  private router: Router = inject(Router);

  @Input() isMobile = false;

  @Input() visibility = 'show';

  @HostBinding('class.mobile') get mobileClass(): boolean {
    return this.isMobile;
  }

  @HostListener('window:click', [ '$event' ]) onClick(event: PointerEvent): void {
    const clickPosition = event.clientX;

    if (this.isMobile && clickPosition > 250) {
      this.state.set('collapsed', true);
    }
  }

  collapsed$ = this.state.collapsed$;
  dashboardRoute: DashboardRoute = '/dashboards';
  isMobileLayout = false;
  menuItems = [];

  offlineModeEnabled$ = combineLatest([ this.facilityState.moduleSettings$, this.networkState.isOffline$ ]).pipe(
    map(([ moduleSettings, isOffline ]) => !!moduleSettings?.OfflineMode && isOffline)
  );

  openGroupID:  WritableSignal<number> = signal(null);

  navGroups$ = combineLatest([
    this.state.navLinks$,
    this.offlineModeEnabled$,
    this.facilityState.offlinePermitTypes$
  ]).pipe(map(([
    navLinks,
    offlineModeEnabled,
    offlinePermitTypes
  ]) => {
    if (offlineModeEnabled) {
      return navLinks.filter(link => offlinePermitTypes.includes(link.itemType));
    }

    return navLinks;
  }));

  unsyncedOfflinePermitTypes$ = this.navGroups$
    .pipe(
      switchMap(() => this.indexedDB.db.permits.toArray()),
      map(permits => uniq(_map(permits, 'tableName')))
    );

  get facilityId(): number {
    return this.appState.get('facilityId');
  }

  ngOnInit() {
    let canCustomDashboard = this.facilityState.get('moduleSettings')?.CustomDashboards;
    this.dashboardRoute = canCustomDashboard ? '/dashboards' : '/announcements';

    this.layoutService.isMobile$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((isMobile: boolean) => {
      this.isMobileLayout = isMobile;
    });
  }

  setOpenGroup(groupID: number): void {
    this.openGroupID.update(openGroupID => groupID === openGroupID ? null : groupID);
  }


  isActive({ permitGroupID }): boolean {
    const isPermitLink = this.state.get('activePermitGroup');
    if (isPermitLink) {
      return this.state.get('activePermitGroup') === permitGroupID;
    }
  }

  dashboardsActive(route: any): boolean {
    return this.router.url.includes(route);
  }

}
