import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import camelCase from 'lodash/camelCase';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { SafeTkTableColumn } from 'src/app/safetk-table/components/safetk-table/safetk-table.component';
import { SettingsService } from '~core/services/settings/settings.service';
import { DateService } from '~date/date-service/date.service';
import { SafeTKDB } from '~indexedDB';

export const visibleColumnTemplateMap = {
  createdDate: 'date',
  createdOffline: 'icon',
  lockedForOffline: 'checkbox',
  statusDateTime: 'date'
};

export const visibleColumnConfigMap = {
  createdOffline: {
    icon: 'checkmark',
    iconClass: 'text-green-600'
  },
  lockedForOffline: {
    icon: 'checkmark'
  }
};

@Injectable({
  providedIn: 'root'
})
export class OfflinePermitIndexResolver  {

  private indexedDB = inject(SafeTKDB);
  private settingsSvc = inject(SettingsService);
  private dateService = inject(DateService);

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const { permitType } = route.params;

    const indexSettings = this.settingsSvc.getFilteredSettings({
      feature: 'index',
      isStandard: false,
      permitType
    }, true);

    const colOrder = indexSettings?.settings?.columnOrder;
    const visible: string[] = [ ...(indexSettings?.settings?.visibleColumns ?? []), 'createdOffline' ];


    const tableColumns: SafeTkTableColumn[] = map(visible, (col) => {
      const tableColumn: SafeTkTableColumn = {
        editable: false,
        name: camelCase(col),
        template: visibleColumnTemplateMap?.[col] ?? 'text',
        config: {
          controlType: visibleColumnTemplateMap?.[col] ?? 'text',
          referenceProp: 'name'
        }
      };

      if (visibleColumnConfigMap?.[col]) {
        tableColumn.config = {
          ...tableColumn.config,
          ...visibleColumnConfigMap?.[col]
        };
      }

      return tableColumn;
    });

    const visibleColumns = sortBy(tableColumns, col => +colOrder?.[col.name]);

    let permits = await this.indexedDB.db.permits.where('tableName').equals(permitType)
      .toArray();

    const key = `${permitType}AssociatedUserField`;
    let assUsersFields = await this.indexedDB.db.permitAssociatedUserFields.where('tableName').equals(key)
      .toArray();

    const assUserLookup = new Map();
    assUsersFields.forEach(assUser => {
      const permitKey = `${assUser.parentID}-${camelCase(assUser.associatedUserFieldName)}`;
      assUserLookup.set(permitKey, assUser);
    });

    permits = permits.map(permit => {
      visible.forEach(col => {
        // Check for both the user field and its datetime field
        const baseFieldName = col.replace('DateTime', '');
        const lookupKey = `${permit.ID}-${camelCase(baseFieldName)}`;
        const matchingAssUser = assUserLookup.get(lookupKey);

        if (matchingAssUser) {
          permit[camelCase(matchingAssUser?.associatedUserFieldName)] = matchingAssUser?.userFullName;
          permit[camelCase(`${matchingAssUser?.associatedUserFieldName}DateTime`)] = this.dateService.convertDateForDisplay(matchingAssUser?.associatedUserFieldDate) || null;
        }
      });

      permit.rowColor = indexSettings?.settings?.statusColors?.[permit?.currentStatusName] ?? 'white';
      return permit;
    });

    return {
      columns: visibleColumns,
      permits
    };
  }
}
