import { NgModule, provideEnvironmentInitializer } from '@angular/core';

import { initializeOfflineService } from './services/offline/offline.service';



@NgModule({
  providers: [ provideEnvironmentInitializer(initializeOfflineService) ]
})
export class OfflineModule { }
