import { CommonModule } from '@angular/common';
import {
  NgModule, inject, provideAppInitializer 
} from '@angular/core';

import { connectToSignalR, SignalrService } from './services/signalr/signalr.service';


@NgModule({
  declarations: [],
  imports: [ CommonModule ],
  providers: [
    provideAppInitializer(() => {
      const initializerFn = (connectToSignalR)(inject(SignalrService));
      return initializerFn();
    })
  ]
})
export class SignalrModule { }
